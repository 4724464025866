import imageRequest from "../../models/request/image";

export default {
  new(data) {
    this.images = helper.clone(data.pictures).map((image) => {
      image.desktop = imageRequest(image.desktop.files, false);
      image.mobile = imageRequest(image.mobile.files, false);
      image.newTab = image.newTab === true ? 1 : 0;
      image.active = image.active === true ? 1 : 0;
      if (image.id) {
        delete image.path;
        if (!image.file) {
          delete image.size;
          delete image.type;
        }
      }

      return image;
    });

    this.title = data.title;
    this.show_title = data.show.title === true ? 1 : 0;
    this.show_description = data.show.description === true ? 1 : 0;
    this.show_buttons = data.show.buttons === true ? 1 : 0;
    this.auto = data.auto === true ? 1 : 0;
    this.showTime = data.showTime;
    this.changeTime = data.changeTime;
    this.categories = data.categories;
    this.models = data.models;
    this.brands = data.brands;
    return this;
  },

  images: [],
  title: null,
  show_title: 0,
  categories: [],
  models: [],
  brands: [],
  show_description: 0,
  show_buttons: 0,
  auto: 0,
  showTime: 4000,
  changeTime: 1000,
};
